import {
  registerApplication,
  start,
  getAppStatus,
  LOAD_ERROR,
  addErrorHandler,
  removeErrorHandler,
} from "single-spa";

registerApplication({
  name: "@bam/auth",
  app: () => System.import("@bam/auth"),
  activeWhen: ["/"],
});

const errorHandler = (err) => {
  console.error(
      `App failed: ${err.appOrParcelName} with status: ${getAppStatus(
          err.appOrParcelName
      )}`
  );
  console.error(err);
  if (getAppStatus(err.appOrParcelName) === LOAD_ERROR) {
    System.delete(System.resolve(err.appOrParcelName));
  }
  if (
      err.appOrParcelName === "@bam/container" ||
      err.appOrParcelName === "@bam/auth"
  ) {
    window.showFullPageError(err.appOrParcelName, err);
  } else {
    window.logResourceError(err.appOrParcelName, err);
  }
};

addErrorHandler(errorHandler);

window.addEventListener("bam-auth:authentication-redirect", () => {
  // We want to stop reporting resource errors when we are redirecting for authentication because we would likely be
  // cancelling in-progress requests for @find-care/container, or other MFEs that will then incorrectly report as failures to Dynatrace
  removeErrorHandler(errorHandler);
});

window.addEventListener("bam-auth:authorized", () => {
  registerApplication({
    name: "@bam/container",
    app: () => System.import("@bam/container"),
    activeWhen: ["/"],
  });
});

window.addEventListener("single-spa:app-change", (event) => {
  // @ts-ignore
  const { detail } = event;
  if (detail?.newAppStatuses["@bam/container"] === "MOUNTED") {
    // Remove loading spinner
    document.getElementById("loading-view").style.display = "none";
    document.getElementById("main-content").style.display = "block";
  }
});

start({
  urlRerouteOnly: true,
});